import { assert } from '@kontent-ai/utils';
import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { ICollectionsRepository } from './interfaces/ICollectionsRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  ICollectionUsageServerResponseModel,
  ICollectionsServerResponseModel,
  IUpdateCollectionsServerRequestModel,
} from './serverModels/CollectionsServerModel.type.ts';

const getCollectionsCacheKey = (projectId: Uuid | undefined) =>
  Cache.getKey('collections', projectId);

const getCollectionUsageCacheKey = (collectionId: Uuid, projectId: Uuid | undefined) =>
  Cache.getKey('collection-usage', collectionId, projectId);

const getGeneralInvalidationKey = (projectId: Uuid | undefined) =>
  getCollectionsCacheKey(projectId);

const clearCache = (projectId?: Uuid): void =>
  Cache.throwAway(getGeneralInvalidationKey(projectId));

const getDraftProjectCollectionsApiUrl = (projectId?: Uuid): string =>
  `${getUrlFactory().getDraftProjectApiUrl(projectId)}/collections`;

const restProvider = createRestProvider(createAjaxWithCredentials());

export const collectionsRepository: RepositoryWithContext<ICollectionsRepository> = {
  async get(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ICollectionsServerResponseModel> {
    const projectId = requestContext.projectId;
    assert(projectId, () => 'requestContext has no initialized project');

    return this.getProjectCollections(requestContext, projectId, abortSignal);
  },

  async getProjectCollections(
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ICollectionsServerResponseModel> {
    const cacheKey = getCollectionsCacheKey(projectId);
    const invalidationKeys = [getGeneralInvalidationKey(projectId)];

    const url = getDraftProjectCollectionsApiUrl(projectId);
    const getValue = () => restProvider.get(url, null, abortSignal, requestContext);

    return Cache.cache(
      cacheKey,
      invalidationKeys,
      getValue,
      getMilliseconds({ seconds: 1 }),
      abortSignal,
    );
  },

  async getUsage(
    requestContext: IRequestContext,
    collectionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ICollectionUsageServerResponseModel> {
    const cacheKey = getCollectionUsageCacheKey(collectionId, requestContext.projectId);
    const invalidationKeys = [getGeneralInvalidationKey(requestContext.projectId)];

    const url = `${getDraftProjectCollectionsApiUrl(
      requestContext.projectId,
    )}/usage/${collectionId}`;
    const getValue = () => restProvider.get(url, null, abortSignal, requestContext);

    return Cache.cache(
      cacheKey,
      invalidationKeys,
      getValue,
      getMilliseconds({ milliseconds: 500 }),
      abortSignal,
    );
  },

  async update(
    requestContext: IRequestContext,
    updatedCollections: IUpdateCollectionsServerRequestModel,
    abortSignal?: AbortSignal,
  ): Promise<ICollectionsServerResponseModel> {
    const url = getDraftProjectCollectionsApiUrl(requestContext.projectId);
    const collectionsServerModel = await restProvider.put(
      url,
      updatedCollections,
      abortSignal,
      requestContext,
    );

    clearCache(requestContext.projectId);

    return collectionsServerModel;
  },
};
