type ServerConfig = {
  readonly draftApiUrl: string;
  readonly openLocalUrlOnStart?: boolean;
  readonly watchIgnored: ReadonlyArray<string>;
};

export const getLocalConfig = (
  serverEnv?: ReadonlyRecord<string, string>,
): {
  readonly server: ServerConfig;
  readonly client: Partial<ReadonlyRecord<keyof Omit<ClientConfig, 'clientVersion'>, string>>;
  readonly environment: Partial<ReadonlyRecord<keyof EnvironmentConfig, string>>;
} => {
  const env = serverEnv ?? import.meta.env;

  if (!env.VITE_SERVER_DRAFT_API_URL) {
    throw new Error('Missing dotenv value for VITE_SERVER_DRAFT_API_URL.');
  }

  return {
    server: {
      draftApiUrl: env.VITE_SERVER_DRAFT_API_URL,
      openLocalUrlOnStart: env.VITE_SERVER_OPEN_LOCAL_URL_ON_START === 'true',
      watchIgnored: env.VITE_SERVER_WATCH_IGNORED?.split(',') ?? [],
    },
    client: {
      enableReduxActionLogging: env.VITE_CLIENT_ENABLE_REDUX_ACTION_LOGGING,
      enableReduxDevtools: env.VITE_CLIENT_ENABLE_REDUX_DEVTOOLS,
      logErrorsToConsole: env.VITE_CLIENT_LOG_ERRORS_TO_CONSOLE,
      prefersReducedMotion: env.VITE_CLIENT_PREFERS_REDUCED_MOTION,
      reduceDelay: env.VITE_CLIENT_REDUCE_DELAY,
    },
    environment: {
      amplitudeApiKey: env.VITE_ENV_AMPLITUDE_API_KEY,
      amplitudeSearchUrl: env.VITE_ENV_AMPLITUDE_SEARCH_URL,
      appInsightsInstrumentationKey: env.VITE_ENV_APP_INSIGHTS_INSTRUMENTATION_KEY,
      auth0ApiIdentifier: env.VITE_ENV_AUTH0_API_IDENTIFIER,
      auth0ClaimNamespace: env.VITE_ENV_AUTH0_CLAIM_NAMESPACE,
      auth0ClientId: env.VITE_ENV_AUTH0_CLIENT_ID,
      auth0Domain: env.VITE_ENV_AUTH0_DOMAIN,
      azuremarketplaceAppId: env.VITE_ENV_AZURE_MARKETPLACE_APP_ID,
      churnZeroAppKey: env.VITE_ENV_CHURN_ZERO_APP_KEY,
      defaultProjectLocation: env.VITE_ENV_DEFAULT_PROJECT_LOCATION,
      deliverLiveContentDomain: env.VITE_ENV_DELIVER_LIVE_CONTENT_DOMAIN,
      devAiServiceBusTopicName: env.VITE_ENV_DEV_AI_SERVICE_BUS_TOPIC_NAME,
      draftNotificationUrl: env.VITE_ENV_DRAFT_NOTIFICATION_URL,
      enabledFeatures: env.VITE_ENV_ENABLED_FEATURES,
      fastSpringBaseUrl: env.VITE_ENV_FAST_SPRING_BASE_URL,
      gtmParams: env.VITE_ENV_GTM_PARAMS,
      intercomAppId: env.VITE_ENV_INTERCOM_APP_ID,
      isAmplitudeEnabled: env.VITE_ENV_IS_AMPLITUDE_ENABLED,
      isChurnZeroEnabled: env.VITE_ENV_IS_CHURN_ZERO_ENABLED,
      isGtmEnabled: env.VITE_ENV_IS_GTM_ENABLED,
      isHotjarEnabled: env.VITE_ENV_IS_HOTJAR_ENABLED,
      isIntercomTrackingEnabled: env.VITE_ENV_IS_INTERCOM_TRACKING_ENABLED,
      isPardotEnabled: env.VITE_ENV_IS_PARDOT_ENABLED,
      isSentryDebuggingEnabled: env.VITE_ENV_IS_SENTRY_DEBUGGING_ENABLED,
      isSentryEnabled: env.VITE_ENV_IS_SENTRY_ENABLED,
      jsonPrettifierUrl: env.VITE_ENV_JSON_PRETTIFIER_URL,
      landingPageUrl: env.VITE_ENV_LANDING_PAGE_URL,
      learnPortalUrl: env.VITE_ENV_LEARN_PORTAL_URL,
      logToLocalStorageItem: env.VITE_ENV_LOG_TO_LOCAL_STORAGE_ITEM,
      pardotUrl: env.VITE_ENV_PARDOT_URL,
      productUpdateEndpoint: env.VITE_ENV_PRODUCT_UPDATE_ENDPOINT,
    },
  };
};
