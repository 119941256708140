import { notNullNorUndefined } from '@kontent-ai/utils';
import { version } from '../../package.json';
import { getLocalConfig } from '../../vite/localConfigLoader.ts';

const logConsoleError: typeof console.error = (...params) => {
  /**
   * We can’t use the logConsoleError from logError.ts as that one depends on clientConfig.
   */

  console.error(...params);
};

const settings = [
  {
    settingName: 'enableReduxActionLogging',
    storageKey: 'config-enableReduxActionLogging',
    parser: parseBoolean,
    enabledByFeatureToggle: null,
  },
  {
    settingName: 'enableReduxDevtools',
    storageKey: 'config-enableReduxDevtools',
    parser: parseBoolean,
    enabledByFeatureToggle: 'EnableReduxDevtools',
  },
  {
    settingName: 'logErrorsToConsole',
    storageKey: 'config-logErrorsToConsole',
    parser: parseBoolean,
    enabledByFeatureToggle: 'LogErrorsToConsole',
  },
  {
    settingName: 'prefersReducedMotion',
    storageKey: 'config-prefersReducedMotion',
    parser: parseBoolean,
    enabledByFeatureToggle: null,
  },
  {
    settingName: 'reduceDelay',
    storageKey: 'config-reduceDelay',
    parser: parseBoolean,
    enabledByFeatureToggle: null,
  },
] as const;

function parseBoolean(key: string, value: string): boolean | undefined {
  const _value = value.trim().toLowerCase();
  if (['true', 'false'].includes(_value)) {
    return _value === 'true';
  }
  logConsoleError(
    `Client config parser: Unexpected value "${value}" for local storage key "${key}". Can be "true" or "false".`,
  );

  return undefined;
}

function parseClientConfig(
  localClientConfig: Partial<ReadonlyRecord<keyof Omit<ClientConfig, 'clientVersion'>, string>>,
): ClientConfig {
  const configPairs = settings
    .map((item) => {
      const localStorageValue = self.localStorage.getItem(item.storageKey);
      if (localStorageValue !== null) {
        return [item.settingName, item.parser(item.storageKey, localStorageValue)];
      }

      const localConfigValue = localClientConfig[item.settingName] ?? null;
      if (localConfigValue !== null) {
        return [item.settingName, localConfigValue];
      }

      const featureToggleValue = item.enabledByFeatureToggle
        ? self._envConfig.enabledFeatures.includes(item.enabledByFeatureToggle)
        : null;
      if (featureToggleValue !== null) {
        return [item.settingName, featureToggleValue];
      }
      return [item.settingName, null];
    })
    .filter(([, value]) => notNullNorUndefined(value));

  return Object.fromEntries([['clientVersion', version], ...configPairs]);
}

function setClientConfig(): void {
  try {
    /**
     * (self as Mutable<Pick<typeof self, '_clientConfig'>>) makes the _clientConfig writable.
     * Which is alright here. It's readonly for the rest of the app.
     */
    (self as Mutable<Pick<typeof self, '_clientConfig'>>)._clientConfig = parseClientConfig(
      getLocalConfig(import.meta.env).client,
    );
  } catch (e) {
    (self as Mutable<Pick<typeof self, '_clientConfig'>>)._clientConfig = {};
    logConsoleError(e);
  }
}

function localStorageChanged(e: StorageEvent): void {
  const hasClientConfigChanged = settings.some(({ storageKey }) => storageKey === e.key);
  if (hasClientConfigChanged) {
    setClientConfig();
    self.dispatchEvent(new Event('clientConfigChanged'));
  }
}

setClientConfig();

self.addEventListener('storage', localStorageChanged);
