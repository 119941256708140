import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { AiAcceleratorsFeatureCodename } from '../applications/environmentSettings/aiAccelerators/types/AiAcceleratorsFeatureCodenames.ts';
import { IAiAcceleratorsRepository } from './interfaces/IAiAcceleratorsRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const aiAcceleratorsRepository: RepositoryWithContext<IAiAcceleratorsRepository> = {
  enableFeature(
    requestContext: IRequestContext,
    featureName: AiAcceleratorsFeatureCodename,
    abortSignal?: AbortSignal,
  ) {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/early-access-program/features/${featureName}`;
    return restProvider.put(url, undefined, abortSignal, requestContext);
  },
  disableFeature(
    requestContext: IRequestContext,
    featureName: AiAcceleratorsFeatureCodename,
    abortSignal?: AbortSignal,
  ) {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/early-access-program/features/${featureName}`;
    return restProvider.delete(url, abortSignal, requestContext);
  },
};
