import { TZDate, tz, tzOffset } from '@date-fns/tz';
import { InvariantException } from '@kontent-ai/errors';
import { format, transpose } from 'date-fns';
import obsoleteTimeZones from '../../../../../timeZones/obsoleteTimeZones.generated.json';
import standardTimeZones from '../../../../../timeZones/standardTimeZones.generated.json';

export const getTimezoneCurrentOffset = (timeZoneId: string): number =>
  tzOffset(timeZoneId, new Date());

export const ensureBrowserCompatibleTimeZone = (timeZoneId: string): string => {
  if (!Number.isNaN(getTimezoneCurrentOffset(timeZoneId))) {
    return timeZoneId;
  }

  const fallbackTimeZone = findBrowserCompatibleTimeZoneFallback(timeZoneId);
  if (!fallbackTimeZone) {
    throw InvariantException(
      `${timeZoneId} is not supported by the browser and has no fallback. (${ensureBrowserCompatibleTimeZone.name})`,
    );
  }

  return fallbackTimeZone;
};

const findBrowserCompatibleTimeZoneFallback = (timeZoneId: string): string | undefined =>
  standardTimeZones
    .find((timeZone) => timeZone.name === findCardinalTimeZoneForId(timeZoneId, standardTimeZones))
    ?.group.find((timeZone) => !Number.isNaN(getTimezoneCurrentOffset(timeZone)));

export const transferBetweenTimeZonesKeepingLocalTime = (
  utcDateTime: DateTimeStamp,
  fromTimeZoneId: string,
  toTimeZoneId: string,
): DateTimeStamp => {
  const ensuredFromTimeZoneId = ensureBrowserCompatibleTimeZone(fromTimeZoneId);
  const ensuredToTimeZoneId = ensureBrowserCompatibleTimeZone(toTimeZoneId);

  if (fromTimeZoneId === toTimeZoneId) {
    return utcDateTime;
  }

  return new Date(
    transpose(
      new TZDate(utcDateTime, ensuredFromTimeZoneId),
      tz(ensuredToTimeZoneId),
    ).toISOString(),
  ).toISOString();
};

export const findCardinalTimeZoneForId = (
  id: string,
  timeZones: ReadonlyArray<{ readonly name: string; readonly group: ReadonlyArray<string> }>,
): string | null =>
  timeZones.find((timeZone) => timeZone.name === id)?.name ??
  timeZones.find((timeZone) => timeZone.group.includes(id))?.name ??
  null;

export const getObsoleteTimeZoneNameTooltipText = (id: string): string =>
  `This time zone name is obsolete. Please use the new name ${findCardinalTimeZoneForId(
    id,
    standardTimeZones,
  )}.`;

export const getFormattedTimeZoneOffset = (dateTime: DateTimeStamp, timeZoneId: string): string => {
  const offset = format(new TZDate(dateTime, timeZoneId), 'XXX');
  return offset === 'Z' ? '+00:00' : offset;
};

/**
 * Considers the input to be a local datetime in the specified time zone ignoring the current
 * system time zone and returns the equivalent UTC datetime for that.
 * For example:
 * 27.9.2024 9:00 in system time zone Europe/Prague is 7:00 UTC.
 * To keep 9:00 in Europe/Helsinki the function returns 6:00 UTC.
 * @param date The date which values represent the local time.
 * @param fromTimeZoneId The timezone of this local time.
 */
export const fromZonedTimeToUtc = (date: Date, fromTimeZoneId: string): Date =>
  Number.isNaN(date.getTime()) || Number.isNaN(getTimezoneCurrentOffset(fromTimeZoneId))
    ? new Date(Number.NaN)
    : new Date(transpose(date, tz(fromTimeZoneId)).toISOString());

/**
 * Returns a date instance with values representing the local time in the time zone
 * specified of the UTC time from the date provided, ignoring the current system time zone.
 * For example:
 * 27.9.2024 9:00 UTC is equivalent to 11:00 local date instance in Europe/Prague system time zone.
 * To keep 9:00 UTC in Europe/Helsinki, the function returns the date instance 27.9.2024 12:00.
 * @param date The date which values represent the UTC time.
 * @param toTimeZoneId The timezone to get the local time for.
 */
export const fromUtcToZonedTime = (date: Date, toTimeZoneId: string): Date =>
  Number.isNaN(date.getTime()) || Number.isNaN(getTimezoneCurrentOffset(toTimeZoneId))
    ? new Date(Number.NaN)
    : new Date(transpose(new TZDate(date, toTimeZoneId), Date).toISOString());

export const UtcTimeZoneId = 'UTC';
export const StandardTimeZoneIds = new Set<string>(
  standardTimeZones.map((timeZone) => timeZone.name),
);
export const ObsoleteTimeZoneIds = new Set<string>(
  obsoleteTimeZones.map((timeZone) => timeZone.name),
);
export const LocalTimeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone ?? UtcTimeZoneId;
export const CardinalLocalTimeZoneId = findCardinalTimeZoneForId(
  LocalTimeZoneId,
  standardTimeZones,
);
export const DefaultDisplayTimeZoneId = CardinalLocalTimeZoneId ?? UtcTimeZoneId;
