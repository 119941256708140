import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IProjectEnvironmentSettingsRepository } from './interfaces/IProjectEnvironmentSettingsRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { ILivePreviewConfigurationServerModel } from './serverModels/LivePreviewConfigurationServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const projectEnvironmentSettingsRepository: RepositoryWithContext<IProjectEnvironmentSettingsRepository> =
  {
    getLivePreviewConfiguration(requestContext, projectId: Uuid, abortSignal?: AbortSignal) {
      const url = `${getUrlFactory().getDraftProjectApiUrl(
        projectId,
      )}/project-environment-settings/live-preview-configuration`;
      return restProvider.get(url, undefined, abortSignal, requestContext);
    },
    setLivePreviewConfiguration(
      requestContext: IRequestContext,
      projectId: Uuid,
      livePreviewConfiguration: ILivePreviewConfigurationServerModel,
      abortSignal?: AbortSignal,
    ) {
      const url = `${getUrlFactory().getDraftProjectApiUrl(
        projectId,
      )}/project-environment-settings/live-preview-configuration`;
      return restProvider.put(url, livePreviewConfiguration, abortSignal, requestContext);
    },
  };
