import { format, isMatch, isValid, parse } from 'date-fns';
import { cartesianMultiply, toArray } from '../arrayUtils/arrayUtils.ts';
import { fromZonedTimeToUtc } from './timeZoneUtils.ts';

export const DateReadableFormat = 'PP';
const TimeReadableFormat = 'h:mm aaa';
const dateFormats = [
  'yyyy-MM-dd',
  'd.M.yyyy',
  'dd.MM.yyyy',
  'dd.MMM.yyyy',
  'MMM d, yyyy,',
  'P',
  'PP',
];
const timeFormats = [
  'h:mm aaaaa',
  'h:mm aaaa',
  'h:mm aaa',
  'h:mm aa',
  'h:mm a',
  'hh:mm aaaaa',
  'hh:mm aaaa',
  'hh:mm aaa',
  'hh:mm aa',
  'hh:mm a',
  'hh:mm',
  'HH:mm',
];
const datetimeFormats = cartesianMultiply(dateFormats, timeFormats)
  .map(([dateFormat, timeFormat]) => {
    return `${dateFormat} ${timeFormat}`;
  })
  .concat(dateFormats)
  .concat(timeFormats);

const makeDate = (
  returnToUTC: boolean | undefined,
  input?: Date | string | number,
  formats: string | string[] = datetimeFormats,
): Date => {
  let date = new Date();
  const formatArray = toArray(formats);
  if (input) {
    if (typeof input === 'string') {
      if (input !== '') {
        date = new Date(input);
        // first we have to try our formats mainly because of "d.M.yyyy" format because
        // dates strings like "2.3.2000" going through new Date() takes first number as Month
        let foundFormatMatch = false;
        for (const dateFormat of formatArray) {
          if (isMatch(input, dateFormat)) {
            date = parse(input, dateFormat, new Date());
            foundFormatMatch = true;
            break;
          }
        }
        if (!foundFormatMatch && Date.parse(input)) {
          date = new Date(input);
        }
      }
    } else {
      date = new Date(input);
    }
  }

  if (returnToUTC) {
    return fromZonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  }
  return date;
};

export const parseDatetime = (datetimeString: string, utc: boolean | undefined): Date => {
  if (datetimeString === '') {
    throw new Error('datetimeUtils.ts: Empty string cannot be parsed as a Date object');
  }

  return makeDate(utc, datetimeString, datetimeFormats);
};

export const formatDatetimeToReadable = (
  datetime: Date | null,
  withTime: boolean | undefined,
): string | null => {
  if (!datetime || !isValid(datetime)) {
    return null;
  }

  const formatStr = `${DateReadableFormat}${withTime ? `, ${TimeReadableFormat}` : ''}`;

  return format(datetime, formatStr);
};

export const formatTimeToReadable = (datetime: Date | null): string | null => {
  if (!datetime || !isValid(datetime)) {
    return null;
  }

  return format(datetime, TimeReadableFormat);
};
