import { getHttpRequestErrorMessage } from '@kontent-ai/errors';
import {
  ApplicationInsights,
  ICustomProperties,
  IExceptionTelemetry,
  ITelemetryItem,
  Snippet,
} from '@microsoft/applicationinsights-web';
import { detect } from 'detect-browser';

const noOperation = (): void => undefined;
const browser = detect();

let appInsights: ApplicationInsights | null = null;

const swallowCaughtErrorAndLogIt = (callee: () => void): void => {
  try {
    callee();
  } catch (e) {
    console.error(e);
  }
};

const addBrowserContextData = (telemetry: ITelemetryItem): void => {
  telemetry.baseData = {
    ...telemetry.baseData,
    properties: {
      'component.name': 'DraftFrontend',
      url: self.location.pathname,
      browserName: browser?.name || 'NotDetected',
      browserVersion: browser?.version || 'NotDetected',
      clientVersion: self._clientConfig.clientVersion || 'NotSet',
      os: browser?.os || 'NotDetected',
    },
  };
};

const getCustomChannels = (): Snippet['config']['channels'] => {
  const { logToLocalStorageItem } = self._envConfig;

  if (logToLocalStorageItem) {
    return [
      [
        {
          identifier: 'localStorageTelemetryChannel',
          priority: 10000,
          pause: noOperation,
          resume: noOperation,
          teardown: noOperation,
          flush: noOperation,
          initialize: noOperation,
          processTelemetry: (telemetry) => {
            const old = localStorage.getItem(logToLocalStorageItem);
            localStorage.setItem(
              logToLocalStorageItem,
              [JSON.stringify(telemetry), old].filter((t) => t).join(),
            );
          },
        },
      ],
    ];
  }
  return undefined;
};

/**
 * Errors raised due to an outdated browser must be covered in notSupportedBrowserWarning.pug script.
 */
const swallowErrorMessages: ReadonlyArray<[substring: string, swallow?: () => boolean]> = [
  ['ResizeObserver loop completed with undelivered notifications.'], // Firefox
  ['ResizeObserver loop limit exceeded'], // Chrome
  [
    'this.keyAliases is undefined',
    () => {
      return browser?.name === 'firefox' && Number.parseInt(browser.version, 10) < 110;
    },
  ],
  [
    '',
    () => {
      // The goal is to ignore all errors caused by unsupported Safari browsers.
      return browser?.name === 'safari' && Number.parseFloat(browser.version) <= 16.6;
    },
  ],
  ['Object Not Found Matching Id'], // Most likely Outlook browser extension safe scanning a smart link
  [getHttpRequestErrorMessage(0)], // Connection errors, they are not useful to log and analyze
  ['ErrorEvent: Script error.', () => browser?.name === 'safari' || browser?.os === 'iOS'], // See KCL-10918
  ['does not correspond with provided preview URL origin'], // Origin of the iframe message received by Web Spotlight is different from preview URL
];

type TelemetryItem = ITelemetryItem & {
  readonly baseData?: {
    readonly exceptions?: ReadonlyArray<Exception>;
  };
  readonly data?: {
    readonly message: string;
  };
};

type Exception = {
  readonly message: string;
  readonly stack: string;
};

const filterOutIgnoredErrors = (telemetry: TelemetryItem): boolean => {
  const shouldSwallow = swallowErrorMessages.some(([substring, swallow]) => {
    const telemetryContainsMessage =
      telemetry.data?.message?.includes(substring) ||
      telemetry.baseData?.exceptions?.some((e) => e.message.includes(substring));
    return telemetryContainsMessage && (swallow?.() ?? true);
  });
  return !shouldSwallow;
};

const obfuscateParamValuesInUriFragment = (uri: string): string => {
  const uriFragmentRegex = /(?<=#)(.*)$/;
  const keyValuePairRegex = /([^=]+)=([^&]+)/g;

  return uri.replace(uriFragmentRegex, (_, uriFragment: string) =>
    uriFragment.replace(keyValuePairRegex, '$1=<omitted>'),
  );
};

const obfuscateSensitiveDataFromUri = (telemetry: TelemetryItem): void => {
  if (telemetry.baseData?.uri) {
    telemetry.baseData.uri = obfuscateParamValuesInUriFragment(telemetry.baseData.uri);
  }
};

const isExceptionWithMessage = (exception: unknown): exception is Exception =>
  exception !== null &&
  typeof exception === 'object' &&
  'message' in exception &&
  typeof exception.message === 'string';

const isExceptionWithStack = (exception: unknown): exception is Exception =>
  exception !== null &&
  typeof exception === 'object' &&
  'stack' in exception &&
  typeof exception.stack === 'string';

const omitBearerFromExceptionProperty = (property: string): string => {
  const bearerRegex = /"Bearer\s([^"]+)"/g;
  return property.replace(bearerRegex, '"Bearer <omitted>"');
};

const obfuscateBearerTokenFromExceptionMessages = (telemetry: ITelemetryItem): void => {
  if (telemetry.baseType === 'ExceptionData' && telemetry?.baseData?.exceptions?.length) {
    for (const exception of telemetry.baseData.exceptions) {
      if (isExceptionWithMessage(exception)) {
        (exception as Mutable<Exception>).message = omitBearerFromExceptionProperty(
          exception.message,
        );
      }
      if (isExceptionWithStack(exception)) {
        (exception as Mutable<Exception>).stack = omitBearerFromExceptionProperty(exception.stack);
      }
    }
  }
};

export const trackExceptionToAppInsights = (
  exception: IExceptionTelemetry,
  customProperties?: ICustomProperties,
): void => {
  swallowCaughtErrorAndLogIt(() => appInsights?.trackException(exception, customProperties));
};

export const initializeApplicationInsights = (): void => {
  const { appInsightsInstrumentationKey } = self._envConfig;

  if (appInsightsInstrumentationKey) {
    swallowCaughtErrorAndLogIt(() => {
      appInsights = new ApplicationInsights({
        config: {
          channels: getCustomChannels(),
          enableAutoRouteTracking: true,
          instrumentationKey: appInsightsInstrumentationKey,
          maxBatchInterval: 2000,
        },
      });
      appInsights.loadAppInsights();
      appInsights.addTelemetryInitializer(addBrowserContextData);
      appInsights.addTelemetryInitializer(filterOutIgnoredErrors);
      appInsights.addTelemetryInitializer(obfuscateSensitiveDataFromUri);
      appInsights.addTelemetryInitializer(obfuscateBearerTokenFromExceptionMessages);
      appInsights.trackPageView();
    });
  }
};

export const trackWarningToAppInsights = (
  message: string,
  customProperties?: ICustomProperties,
): void => {
  appInsights?.trackTrace(
    {
      severityLevel: 2 /* 2 = warning */,
      message,
    },
    customProperties,
  );
};
