type RawEnvironmentConfig = {
  readonly [key in keyof EnvironmentConfig]: string | undefined;
};

const utils = {
  getValidRawValue: <T>(value: T | '-' | null | undefined): T | '' =>
    value === '-' || value === null || value === undefined ? '' : value,
  removeTrailingSlashes: (value: string): string => value.replace(/\/+$/, ''),
  parseArray: (value: string): ReadonlyArray<string> =>
    value
      .split(',')
      .map((item) => item.trim())
      .filter(Boolean),
  parseBoolean: (value: string): boolean => {
    switch (value.toLowerCase().trim()) {
      case 'true':
      case 'yes':
      case '1':
        return true;
      default:
        return false;
    }
  },
  throwIfEmpty: <T>(value: T | '' | undefined, propertyName: string): T => {
    if (!propertyName) {
      throw new Error(
        `Required envConfig property check: Missing argument "propertyName" for value "${value}"`,
      );
    }
    if (value === '' || value === undefined) {
      throw new Error(`Required envConfig property "${propertyName}" is not set.`);
    }
    return value;
  },
};

const parseArray = (rawValue: string | undefined | null) =>
  utils.parseArray(utils.getValidRawValue(rawValue));
const parseBoolean = (rawValue: string | undefined | null) =>
  utils.parseBoolean(utils.getValidRawValue(rawValue));
const parseString = (rawValue: string | undefined | null) => utils.getValidRawValue(rawValue);
const parseStringRequired = (rawValue: string | undefined | null, propertyName: string) =>
  utils.throwIfEmpty(parseString(rawValue), propertyName);
const parseUrl = (rawValue: string | undefined | null) =>
  utils.removeTrailingSlashes(utils.getValidRawValue(rawValue));
const parseUrlRequired = (rawValue: string | undefined | null, propertyName: string) =>
  utils.throwIfEmpty(parseUrl(rawValue), propertyName);

const parseEnvConfigFromRaw = (raw: RawEnvironmentConfig) => ({
  amplitudeApiKey: parseString(raw.amplitudeApiKey),
  amplitudeSearchUrl: parseUrl(raw.amplitudeSearchUrl),
  appInsightsInstrumentationKey: parseString(raw.appInsightsInstrumentationKey),
  auth0ApiIdentifier: parseStringRequired(raw.auth0ApiIdentifier, 'auth0ApiIdentifier'),
  auth0ClaimNamespace: parseStringRequired(raw.auth0ClaimNamespace, 'auth0ClaimNamespace'),
  auth0ClientId: parseStringRequired(raw.auth0ClientId, 'auth0ClientId'),
  auth0Domain: parseStringRequired(raw.auth0Domain, 'auth0Domain'),
  azuremarketplaceAppId: parseString(raw.azuremarketplaceAppId),
  churnZeroAppKey: parseString(raw.churnZeroAppKey),
  defaultProjectLocation: parseStringRequired(raw.defaultProjectLocation, 'defaultProjectLocation'),
  deliverLiveContentDomain: parseUrl(raw.deliverLiveContentDomain),
  devAiServiceBusTopicName: parseString(raw.devAiServiceBusTopicName),
  draftNotificationUrl: parseUrlRequired(raw.draftNotificationUrl, 'draftNotificationUrl'),
  enabledFeatures: parseArray(raw.enabledFeatures),
  fastSpringBaseUrl: parseUrlRequired(raw.fastSpringBaseUrl, 'fastSpringBaseUrl'),
  gtmParams: parseString(raw.gtmParams),
  intercomAppId: parseString(raw.intercomAppId),
  isAmplitudeEnabled: parseBoolean(raw.isAmplitudeEnabled),
  isChurnZeroEnabled: parseBoolean(raw.isChurnZeroEnabled),
  isGtmEnabled: parseBoolean(raw.isGtmEnabled),
  isHotjarEnabled: parseBoolean(raw.isHotjarEnabled),
  isIntercomTrackingEnabled: parseBoolean(raw.isIntercomTrackingEnabled),
  isPardotEnabled: parseBoolean(raw.isPardotEnabled),
  isSentryDebuggingEnabled: parseBoolean(raw.isSentryDebuggingEnabled),
  isSentryEnabled: parseBoolean(raw.isSentryEnabled),
  jsonPrettifierUrl: parseUrl(raw.jsonPrettifierUrl),
  landingPageUrl: parseUrlRequired(raw.landingPageUrl, 'landingPageUrl'),
  learnPortalUrl: parseUrlRequired(raw.learnPortalUrl, 'learnPortalUrl'),
  logToLocalStorageItem: parseString(raw.logToLocalStorageItem),
  pardotUrl: parseUrl(raw.pardotUrl),
  productUpdateEndpoint: parseUrlRequired(raw.productUpdateEndpoint, 'productUpdateEndpoint'),
});

try {
  /**
   * (self as Mutable<Pick<typeof self, '_envConfig'>>) makes the _envConfig writable.
   * Which is alright here. It's readonly for the rest of the app.
   */
  (self as Mutable<Pick<typeof self, '_envConfig'>>)._envConfig = parseEnvConfigFromRaw(
    self._envConfig as any as RawEnvironmentConfig,
  );
} catch (e) {
  /**
   * (self as any) allows us to put null on _envConfig.
   * This will likely crash the app, but that’s kind of the point.
   */
  (self as any)._envConfig = null;
  throw e;
}
